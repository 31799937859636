import React from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import s from "./Keywords.module.less";

import { InputChipQueryOperatorsEnum } from "../../inputChip/InputChipQueryConfig";

export type FilterTagValueType = string | string[] | boolean;

const formatOperator = (text: string) => {
  if (text in InputChipQueryOperatorsEnum) {
    return text.replace("_", " ");
  }

  return text;
};

export function KeywordsTooltipContent(value: FilterTagValueType) {
  const { t } = useTranslation();

  if (typeof value === "object") {
    if (!value?.length) {
      return undefined;
    }

    return value?.map((val, idx) => {
      const text = i18next.exists(`components:tags:values:${val}`)
        ? t(`components:tags:values:${val}`)
        : Array.isArray(val)
          ? val.join(" ")
          : formatOperator(val);

      return (
        <span key={idx} className={s.bbDisplayBlock}>
          {text}
        </span>
      );
    });
  }

  return "";
}
